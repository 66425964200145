import { ModalConfirmDeleteBox } from "../Styles/Modal-Style";




function ModalConfirmDelete({confirmDeleteModalShow, setConfirmDeleteModalShow}) {

    function modalClose() {
        setConfirmDeleteModalShow(false);
    }

    return(
        <>
            <ModalConfirmDeleteBox className={confirmDeleteModalShow ? 'active' : ''}>
                <div className={`delete_box ${confirmDeleteModalShow ? 'active' : ''}`}>
                    <div className="close_btn">
                        <a onClick={modalClose}><i className="fa-solid fa-xmark"></i></a>
                    </div>
                    <div className="box_inner">
                        <h4>Do you want to delete this for sure ?</h4>
                        <div className="btn_sec">
                            <button onClick={modalClose}>No</button>
                            <button>Yes</button>
                        </div>
                    </div>
                </div>
            </ModalConfirmDeleteBox>
        </>
    );
}


export default ModalConfirmDelete;