import { useEffect, useState } from "react";
import { UserWrapper } from "../Styles/User-Style";
import axios from "axios";
import { Fetch_Users_URL } from "../API/Api";




function Users() {

    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [filterType, setFilterType] = useState('Select');
    const filterTypes = ['Username', 'Phone Number', 'City'];

    const [count, setCount] = useState(false);
    const [users, setUsers] = useState([]);
    const [reload, setReload] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {

        axios.get(Fetch_Users_URL)
        .then(res => {
            console.log(res);
            if(res.data.count > 0) {
                setCount(true);
                setUsers(res.data.users);
                setFilteredUsers(res.data.users);
            } else {
                setCount(false);
            }
        })
        .catch(err => {
            // console.log(err);
            setCount(false);
        })

    }, [reload]);

    function handleFilterDropdown() {
        setShowFilterDropdown(!showFilterDropdown);
    }

    return(
        <>
            <UserWrapper>
                <div className="filter_search_sec">
                    <div className="filter_box">
                        <h5>Search By:</h5>
                        <div className="select_box">
                            <input type="text" id="filter" name="filter" required />
                            <div className="dropdown_btn" onClick={handleFilterDropdown}>
                                <p>{filterType}</p>
                                <i class={`fa-solid fa-angle-down ${showFilterDropdown? 'active':''}`}></i>
                            </div>
                            <div className={`dropdown ${showFilterDropdown? 'active':''}`}>
                                <ul>
                                    {
                                        filterTypes.map(filterType => (
                                            <li onClick={(e) => {
                                                setFilterType(filterType)
                                                setShowFilterDropdown(false)
                                                document.getElementById('filter').value = filterType
                                                document.getElementById('filter').click()
                                            }}>{filterType}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="search_box">
                        <h5>Search:</h5>
                        <input type="text" placeholder="Enter Search Input" name="search" required />
                    </div>
                </div>
                <div className="table_sec">
                    <div className="table_inner">
                        <table>
                            <thead>
                                <th>Sl. No.</th>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Phone No.</th>
                                <th>City</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {
                                    count ?
                                    (filteredUsers && filteredUsers.map((user, index) => 
                                        <tr>
                                            <td>{index + 1}.</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone}</td>
                                            <td>{user.city}</td>
                                            <td>
                                                <span><i class="fa-solid fa-eye"></i></span>
                                                <span><i class="fa-solid fa-trash"></i></span>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <p>No Users Found</p>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </UserWrapper>
        </>
    );
}


export default Users;