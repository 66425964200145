import { useEffect, useState } from "react";
import { ModalCommissionEditBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Commission_Add_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function ModalCommissionEdit({commissionEditModalShow, setCommissionEditModalShow, theaterName, setTheaterName}) {

    const rangeslabs = ['Below 100','100 to 129','130 to 179','180 to 199','200 to 249','250 to 299','300 to 349','350 to 399','400 to 449','450 to 499','500 to 549','550 to 599','600 to 699','700 to 749','750 to 799','800 to 849','850 to 899','900 to 950','Above 951'];
    const [range, setRange] = useState('Select Range');
    const [rangeDropdown, setRangeDropdown] = useState(false);
    const [amount, setAmount] = useState('');

    const handleRangeDropdown = () => {
        setRangeDropdown(!rangeDropdown);
    };

    const handleAmount = (e) => {
        setAmount(e.target.value);
    };

    const handleAdminCommission = (e) => {
        e.preventDefault();
        
        const inputs = {
            theater: theaterName,
            commissionType: "admin-commission",
            rangleSlab: range,
            amount: amount,
        }

        axios.post(Commission_Add_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            if(res.data.status == "200") {
                toast.success(`Admin commission against ${range} is settled.`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setRange('Select Range');
                setAmount('');
            }
        })
        .catch(err => {
            console.log(err);
        })
    };

    function closeModal() {
        setCommissionEditModalShow(false);
        setAmount('');
        setTheaterName('');
    };


    return(
        <>
            <ModalCommissionEditBox className={commissionEditModalShow ? 'active' : ''}>
                <div className={`edit_box ${commissionEditModalShow ? 'active' : ''}`}>
                    <div className="close_btn">
                        <a onClick={closeModal}><i className="fa-solid fa-xmark"></i></a>
                    </div>
                    <div className="box_inner">
                        <div className="heading">
                            <h4>Commissions Edit</h4>
                        </div>
                        <div className="form_sec">
                            <div className="theater_commission_sec">
                                <input type="text" required />
                                <span>Theater Commission</span>
                            </div>
                            <div className="admin_convenience_sec">
                                <h5>Admin Convenience</h5>
                                <div className="input_sec">
                                    <div className="range_select_sec">
                                        <input type="text" name="slab_range" value={range} required />
                                        <div className="range_select_btn" onClick={handleRangeDropdown}>
                                            <p>{range}</p>
                                            <i class={`fa-solid fa-angle-down ${rangeDropdown? 'active':''}`}></i>
                                            <span>Range Slab</span>
                                        </div>
                                        <div className={`dropdown ${rangeDropdown? 'active':''}`}>
                                            <ul>
                                                {
                                                    rangeslabs.map((rangeslab, i) => 
                                                        <li key={i}
                                                            onClick={() => {
                                                                setRange(rangeslab)
                                                                setRangeDropdown(false)
                                                            }}
                                                        >{rangeslab}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="amount_input_sec">
                                        <input type="text" value={amount} onChange={handleAmount} required />
                                        <span>Amount</span>
                                    </div>
                                    <div className="add_btn">
                                        <a className={amount ? 'active' : ''} onClick={handleAdminCommission}><i className="fa-solid fa-plus"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_sec">
                                <button><i className="fa-solid fa-pen-to-square"></i>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalCommissionEditBox>
        </>
    );
}


export default ModalCommissionEdit;