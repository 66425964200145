export const Base_URL = "https://ticketbay.in/Backend/SuperAdmin-API";

export const Req_Screen_URL = "https://ticketbay.in/Backend/Screen-Layouts";


// Auth URLs 
export const Login_URL = `${Base_URL}/login.php`;

// Dashboard Data 
export const Dashboard_Data_URL = `${Base_URL}/dashboard-data.php`;

// Find Members 
export const Find_Member_URL = `${Base_URL}/find-member.php`;

// Upload Member Image 
export const Member_Image_Upload = `${Base_URL}/members-image-upload.php`;

// Upload Movie 
export const Upload_Movie_URL = `${Base_URL}/add-movie.php`;

// Show Movies
export const Show_Movies_URL = `${Base_URL}/movie-list.php`;

// Today's Show
export const Today_Shows_URL = `${Base_URL}/today-movie-list.php`;

// Today's Show
export const Requested_Movies_URL = `${Base_URL}/requested-movies.php`;

// Delete Movie
export const Delete_Movie_URL = `${Base_URL}/delete-movie.php`;

// Requested theater table
export const Req_Theaters_URL = `${Base_URL}/req-theater-fetch.php`;

// Active theater table
export const Active_Theaters_URL = `${Base_URL}/fetch-active-theaters.php`;

// Listed theater table
export const Listed_Theaters_URL = `${Base_URL}/listed-theater-fetch.php`;

// Requested theater details
export const Req_Theaters_Details_URL = `${Base_URL}/req-theater-details.php`;

// Requested status update
export const Req_Status_Update_URL = `${Base_URL}/update-status.php`;

// Set no of section
export const SectionNo_Set_URL = `${Base_URL}/set-screen-section.php`;

// Check section per screen
export const SectionNo_Check_URL = `${Base_URL}/check-screen-section.php`;

// Section details add
export const Section_Details_Add_URL = `${Base_URL}/add-section-details.php`;

// Section details add
export const Section_Details_Check_URL = `${Base_URL}/check-section-details.php`;

// Row wide seat add
export const Row_Seats_Add_URL = `${Base_URL}/add-seats-row-wise.php`;

// Row wide seat add
export const Row_Seats_Show_URL = `${Base_URL}/show-temp-seats.php`;

// Commission add
export const Commission_Add_URL = `${Base_URL}/add-update-admin-convenience.php`;

// Fetch seat layout
export const Fetch_Seats_URL = `${Base_URL}/fetch-seat-layout.php`;

// Fetch Theater Details
export const Fetch_Theater_Details_URL = `${Base_URL}/fetch-theater-details.php`;

// Fetch Users
export const Fetch_Users_URL = `${Base_URL}/fetch-users.php`;

// Fetch Reviews
export const Fetch_Reviews_URL = `${Base_URL}/fetch-reviews.php`;