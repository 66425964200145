import { useEffect, useState } from "react";
import { ModalSettingWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Row_Seats_Add_URL, Row_Seats_Show_URL, SectionNo_Check_URL, SectionNo_Set_URL, Section_Details_Add_URL, Section_Details_Check_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function SeatSetting({settingModalShow, setSettingModalShow, settingModalData}) {

    const [start, setStart] = useState('');
    const [gapSeats, setGapSeats] = useState('');
    const [gapAmounts, setGapAmounts] = useState('');
    const [currSection, setCurrSection] = useState('');
    const [currRow, setCurrRow] = useState('');
    const [secName, setSecName] = useState('');
    const [rowsNo, setRowsNo] = useState('');
    const [rowSeats, setRowSeats] = useState('');
    const [modalReload, setModalReload] = useState(false);
    const [secInputShow, setSecInputShow] = useState(true);
    const [secDetailsInputShow, setSecDetailsInputShow] = useState(false);
    const [secDetailsEvent, setSecDetailsEvent] = useState(false);
    const [rowSeatFormShow, setRowSeatFormShow] = useState(false);
    const [submitShow, setSubmitShow] = useState(false);
    const [sectionNo, setSectionNo] = useState('');
    const [screenData, setSCreenData] = useState({
        theaterName: '',
        screen: '',
        screen_id: '',
        section_no: '',
        capacity: ''
    });
    const [sections, setSections] = useState(0);
    const [rows, setRows] = useState(0);
    const [sectionSetBtn, setSectionSetBtn] = useState(false);
    const [sectionDropdown, setsectionDropdown] = useState(false);
    const [rowDropdown, setRowDropdown] = useState(false);
    const [seatResults, setSeatResults] = useState([]);


    useEffect(() => {
        axios.get(`${SectionNo_Check_URL}?id=${settingModalData.screen_id}`)
        .then(res => {
            setModalReload(false);
            console.log(res);
            let status = res.data.status;

            if(status == "200") {
                setSecInputShow(false);
                setSectionNo(res.data.section_no);
                setSections(Number(res.data.section_no) || 0);
                setSecDetailsInputShow(true);
            } else {
                setSectionNo("");
                setSecInputShow(true);
                setSecDetailsInputShow(false);
            }
        })
        .catch(err => {
            console.log(err);
            setSectionNo("");
            setModalReload(false);
            setSecInputShow(true);
            setSecDetailsInputShow(false);
        })
    }, [modalReload, settingModalData]);

    useEffect(() => {
        axios.get(`${Section_Details_Check_URL}?id=${settingModalData.screen_id}`)
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200") {
                setSectionSetBtn(false);
                setCurrSection(res.data.details.section);
                setRowsNo(res.data.details.rowNo);
                setSecName(res.data.details.sec_name);
                setRows(Number(res.data.details.rowNo) || 0);
                setRowSeatFormShow(true);
                setSecDetailsEvent(true);
            } else {
                setRowSeatFormShow(false);
                setSecDetailsEvent(false);
            }
        })
        .catch(err => {
            setRowSeatFormShow(false);
            setSecDetailsEvent(false);
        })
    }, [modalReload, settingModalData]);

    useEffect(() => {
        axios.get(`${Row_Seats_Show_URL}?section=${currSection}&id=${settingModalData.screen_id}`)
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200") {
                setSeatResults(res.data.rows);

                if(res.data.num == rows) {
                    setSubmitShow(true);
                    setSecDetailsInputShow(true);
                } else {
                    setSubmitShow(false);
                }
            }
        })
        .catch(err => {
            console.log(err);
        })
    }, [modalReload, settingModalData]);

    const modalClose = () => {
        setSettingModalShow(false);
    };

    const handleSectionDropdown = () => {
        setsectionDropdown(!sectionDropdown);
    };

    const handleRowDropdown = () => {
        setRowDropdown(!rowDropdown);
    };

    const handleRowsNo = (e) => {
        setRowsNo(e.target.value);
    };

    const handleSectionName = (e) => {
        setSecName(e.target.value);
    };

    const handleRowSeats = (e) => {
        setRowSeats(e.target.value);
    };

    const handleRowStart = (e) => {
        setStart(e.target.value);
    };

    const handleGapSeats = (e) => {
        const value = e.target.value;
        if (value.endsWith(',')) {
            setGapSeats(value + ' ');
        } else {
            setGapSeats(value);
        }
    };

    const handleGapAmounts = (e) => {
        const value = e.target.value;
        if (value.endsWith(',')) {
            setGapAmounts(value + ' ');
        } else {
            setGapAmounts(value);
        }
    };

    const handleSectionNo = (e) => {
        setSectionNo(e.target.value);
        setSCreenData({
            theaterName: settingModalData.theaterName,
            screen: settingModalData.screen,
            screen_id: settingModalData.screen_id,
            section_no: e.target.value,
            capacity: settingModalData.capacity
        });
    };

    const handleSectionNoSet = (e) => {
        e.preventDefault();

        axios.post(SectionNo_Set_URL, screenData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200"){
                toast.success(`No. of sections for ${settingModalData.screen} has been added !!`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setSectionNo("");

                setModalReload(true);
                setSecDetailsInputShow(true);
            }
        })
        .catch(err => {
            console.log(err);
        })
    };

    const handleSectionDetails = (e) => {
        e.preventDefault();

        let layoutData = {
            screen: settingModalData.screen,
            screen_id: settingModalData.screen_id,
            section: currSection,
            secName: secName,
            rowNo: rowsNo,
            step: 'first-step'
        }

        axios.post(Section_Details_Add_URL, layoutData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200"){
                toast.success(`${currSection} details has been added !!`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });

                setModalReload(true);

                setSectionSetBtn(false);

                setTimeout(() => {
                    setModalReload(false);
                }, 1000); 

                setCurrSection('');
                setRowsNo('');
                setSecName('');
            }
        })
        .catch(err => {
            console.log(err);
            let status = err.response.data.status

            if(status == "403"){
                toast.warn(`${currSection} details already exists !!`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else if(status == "505"){
                toast.error(`Something Went Wrong !!`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        })
    };

    const handleRowSeatSubmit = (e) => {
        e.preventDefault();

        if(currRow == "" && rowSeats == "") {
            toast.warn(`Please enter row and seat no.`, {
                position: "top-right",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } else {

            let seatData = {
                screen: settingModalData.screen,
                screen_id: settingModalData.screen_id,
                section: currSection,
                row_no: currRow,
                seats: rowSeats,
                starting: start,
                gap_seats: gapSeats,
                gap_amounts: gapAmounts
            }
    
            axios.post(Row_Seats_Add_URL, seatData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => {
                console.log(res);
                let statusText = res.data.message;
    
                if(statusText == "Added"){
                    toast.success(`${currRow} seats have been added !!`, {
                        position: "top-right",
                        autoClose: 3500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
    
                    setCurrRow('');
                    setRowSeats('');
                    setStart('');
                    setGapSeats('');
                    setGapAmounts('');
                    setModalReload(true);
    
                    setTimeout(() => {
                        setModalReload(false);
                    }, 1000); 
                } else if(statusText == "Updated"){
                    toast.success(`${currRow} seat no has been updated !!`, {
                        position: "top-right",
                        autoClose: 3500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
    
                    setCurrRow('');
                    setRowSeats('');
                    setStart('');
                    setGapSeats('');
                    setGapAmounts('');
                    setModalReload(true);
    
                    setTimeout(() => {
                        setModalReload(false);
                    }, 1000); 
                }
            })
            .catch(err => {
                toast.error(`Something Went Wrong !!`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })

        }
    };

    const handleSectionSubmit = (e) => {
        e.preventDefault();

        let inputs = {
            screen: settingModalData.screen,
            screen_id: settingModalData.screen_id,
            section: currSection,
            secName: secName,
            rowNo: rowsNo,
            step: 'final-step'
        }

        axios.post(Section_Details_Add_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200"){
                toast.success(`${currSection} has completly settled !!`, {
                    position: "top-right",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setCurrSection('');
                setRowsNo('');
                setSecName('');
                setRows(0);
                setModalReload(true);
                setSeatResults([]);
                setSubmitShow(false);
    
                setTimeout(() => {
                    setModalReload(false);
                }, 1000);
            }
        })
        .catch(err => {
            console.log(err);
        })
    };

    return(
        <>
            <ModalSettingWrapper className={settingModalShow ? 'active' : ''}>
                <div className={`wrapper_inner ${settingModalShow ? 'active' : ''}`}>
                    <div className="setting_box">
                        <div className="screen_details">
                            <h5>{settingModalData.screen}</h5>
                            <p>(id: {settingModalData.screen_id})</p>
                        </div>
                        {
                            secInputShow ? 
                            <div className="section_input_box">
                                <div className="input_box">
                                    <input type="text" value={sectionNo} onChange={handleSectionNo} required />
                                    <span>No. of sections</span>
                                </div>
                                <a onClick={handleSectionNoSet}>Set Section</a>
                            </div>
                            :
                            <div className="section_box">
                                <h5>No. of sections :</h5>
                                <p>{sectionNo}</p>
                            </div>
                        }
                        {
                            secDetailsInputShow &&
                            <div className="section_setting_box">
                                <div className="select_box">
                                    <input type="text" name="section" value={currSection} required />
                                    <div className="dropdown_btn" onClick={handleSectionDropdown}>
                                        <p>{currSection}</p>
                                        <span>Select Section</span>
                                        <i class={`fa-solid fa-angle-down ${sectionDropdown? 'rotate':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${sectionDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                Array.from({ length: sections }, (_, index) => 
                                                    <li key={index}
                                                        onClick={() => {
                                                            setCurrSection(`Section ${index + 1}`)
                                                            setsectionDropdown(false)
                                                            setSectionSetBtn(true)
                                                        }}
                                                    >{`Section ${index + 1}`}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="input_box sec_name">
                                    <input type="text" name="rows" value={secName} onChange={handleSectionName} required />
                                    <span>Section Name</span>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="rows" value={rowsNo} onChange={handleRowsNo} required />
                                    <span>No. of Rows</span>
                                </div>
                
                                {sectionSetBtn && <div className="button_box" onClick={handleSectionDetails}><a>Set {currSection}</a></div>}
                                {secDetailsEvent && <div className="event"></div>}
                            </div>
                        }
                        {
                            rowSeatFormShow && 

                            <div className="row_setting_box">
                                <h5><p>Set every row :</p></h5>
                                <div className="select_box">
                                    <input type="text" name="row" value={currRow} required />
                                    <div className="dropdown_btn" onClick={handleRowDropdown}>
                                        <p>{currRow}</p>
                                        <span>Select Row</span>
                                        <i class={`fa-solid fa-angle-down ${rowDropdown? 'rotate':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${rowDropdown? 'active':''}`}>
                                        <div className="dropdown_inner">
                                            <ul>
                                                {
                                                    Array.from({ length: rows }, (_, index) => 
                                                        <li key={index}
                                                            onClick={() => {
                                                                setCurrRow(`Row ${index + 1}`)
                                                                setRowDropdown(false)
                                                            }}
                                                        >{`Row ${index + 1}`}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="seats" value={rowSeats} onChange={handleRowSeats} required />
                                    <span>No. of Seats</span>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="start" value={start} onChange={handleRowStart} required />
                                    <span>Start After</span>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="gap_seats" value={gapSeats} onChange={handleGapSeats} required />
                                    <span>Gap Seats No.</span>
                                </div>
                                <div className="input_box">
                                    <input type="text" name="gap_amount" value={gapAmounts} onChange={handleGapAmounts} required />
                                    <span>Gap Amount</span>
                                </div>
                                <div className="button_box">
                                    <a onClick={handleRowSeatSubmit}><i class="fa-solid fa-plus"></i>Add</a>
                                </div>
                                {
                                    // seatResults.length > 0 && 
                                    <div className="show_result_sec">
                                        <div className="result_inner">
                                            {
                                                seatResults.map((seatResult, index) => 
                                                    <p key={index}><b>{`${seatResult.row_no} ->`}</b>{`${seatResult.seats} seats`}{index < seatResults.length - 1 && <span>, </span>}</p>
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className="buttons_sec">
                            <a className="close" onClick={modalClose}><span><i className="fa-solid fa-xmark"></i>Close</span></a>
                            {submitShow && <a className="submit" onClick={handleSectionSubmit}><span><i className="fa-solid fa-hand-point-right"></i>Submit</span></a>}
                        </div>
                    </div>
                </div>
            </ModalSettingWrapper>
        </>
    );
}



export default SeatSetting;