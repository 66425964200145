import { useEffect, useState } from "react";
import { ScreenViewWrapper } from "../Styles/Modal-Style";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from 'swiper/modules';
import axios from "axios";
import { Fetch_Seats_URL } from "../API/Api";

import 'swiper/css';
import 'swiper/css/free-mode';




function ScreenView({viewModalShow, setViewModalShow, viewModalData, setViewModalData}) {


    const [seatsData, setSeatsData] = useState([]);

    useEffect(() => {
        axios.get(`${Fetch_Seats_URL}?id=${viewModalData.screen_id}`)
        .then(res => {
            console.log(res);
            let status = res.data.status;

            if(status == "200"){
                setSeatsData(res.data.seatData);
            }
        })
        .catch(err => {
            console.log(err);
            setSeatsData([]);
        })
    }, [viewModalData]);


    const modalClose = () => {
        setViewModalShow(false);
        setViewModalData([]);
    };

    const indexToAlphabet = (index) => {
        let letters = '';
        while (index >= 0) {
            letters = String.fromCharCode((index % 26) + 65) + letters;
            index = Math.floor(index / 26) - 1;
        }
        return letters;
    };

    const parseSeatsString = (seatsString) => {
        return seatsString.split(',').map(seat => parseInt(seat.trim(), 10));
    };


    return(
        <>
            <ScreenViewWrapper className={viewModalShow ? 'active' : ''}>
                <div className={`view_inner ${viewModalShow ? 'active' : ''}`}>
                    <div className="top_part">
                        <div className="top_inner">
                            <div className="left_items">
                                <h3>{viewModalData.screen}</h3>
                                <p>[ Id: {viewModalData.screen_id} ]</p>
                            </div>
                            <div className="right_items">
                                <p>Total seat capacity:</p>
                                <span>{viewModalData.capacity}</span>
                            </div>
                        </div>
                    </div>
                    <div className="body_part">
                        <div className="body_inner">
                            <div className="screen_sec">
                                <div className="sec_inner">
                                    <Swiper 
                                        freeMode={true}
                                        slidesPerView={'auto'}
                                        modules={[FreeMode]}
                                        className="mySwiper"
                                        style={{ overflow: 'visible' }}
                                    >
                                        <SwiperSlide>
                                            <div className="screen_box">
                                                <div className="display_image">
                                                    <img src="/images/Screen-Display.svg" alt="Display" />
                                                </div>
                                                <div className="seat_sections">
                                                    {
                                                        seatsData && seatsData.map((seatData, index) => 
                                                        <div className="section" key={index}>
                                                            <div className="sec_name">
                                                                <h5>{seatData.sec_name}</h5>
                                                            </div>
                                                            <div className="sec_seat_rows">
                                                                {
                                                                    (seatData.rows).map((seats, index) => {
                                                                        const gapSeats = parseSeatsString(seats.gap_seats || '');
                                                                        const gapAmounts = parseSeatsString(seats.gap_amounts || '');
                                                                        return(
                                                                            <div className="seat_row" key={index}>
                                                                                <div className="seats">
                                                                                    {
                                                                                        Array.from({ length: seats.seats }, (_, i) => {
                                                                                            const isGap = gapSeats.includes(i + 1);
                                                                                            const gapIndex = gapSeats.indexOf(i + 1);
                                                                                            const marginLeft = isGap && gapIndex !== -1 ? `${gapAmounts[gapIndex] * 40}px` : '0';

                                                                                            return (
                                                                                                <li
                                                                                                    key={i}
                                                                                                    style={i === 0 ? { marginRight: `${seats.starting * 40}px`, marginLeft } : { marginLeft }}
                                                                                                    className={isGap ? 'gap' : ''}
                                                                                                >
                                                                                                    <i className="fa-solid fa-couch"></i>
                                                                                                    <p>{i + 1}</p>
                                                                                                </li>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <span className="index">{indexToAlphabet(index)}</span>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide></SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            <div className="button_sec">
                                <a onClick={modalClose}><span><i className="fa-solid fa-hand-point-right"></i>OK</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </ScreenViewWrapper>
        </>
    );
}


export default ScreenView;