import { styled } from "@mui/material";


export const MovieWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .filter_search_sec {
        position: relative;
        width: 100%;
        height: 55px;
        display: flex;
        align-items: center;

        .filter_box {
            position: relative;
            display: flex;
            height: 100%;
            align-items: center;

            h5 {
                position: relative;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #111;
            }

            .select_box {
                position: relative;
                height: 100%;
                margin-left: 25px;

                input {
                    display: none;
                }

                .dropdown_btn {
                    position: relative;
                    width: 225px;
                    height: 100%;
                    border: 2px solid #E1E0EA;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 25px;
                    font-family: 'Poppins', sans-serif;
                    cursor: pointer;

                    p {
                        position: relative;
                        font-size: 16px;
                        color: #444;
                    }

                    i{
                        position: relative;
                        color: #444;
                        transition: all 0.5s;

                        &.active {
                            transform: rotate(-180deg);
                            transition: all 0.5s;
                        }
                    }
                }

                input:focus ~ .dropdown_btn,
                input:valid ~ .dropdown_btn {
                    border: 2px solid #FC6736;
                }

                .dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background: #FFF;
                    z-index: 50;
                    border-radius: 5px;
                    box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.6s;

                    &.active {
                        max-height: 300px;
                        transition: all 0.6s;
                    }

                    ul {
                        position: relative;
                        width: 100%;
                        padding: 10px 10px;

                        li {
                            position: relative;
                            list-style: none;
                            margin: 5px 0;
                            padding: 10px 15px;
                            color: #555;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            line-height: 1;
                            border-radius: 4px;
                            transition: all 0.5s;
                            cursor: pointer;
                            
                            &:hover {
                                background: #FC6736;
                                color: #FFF;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }

        .search_box {
            position: relative;
            margin-left: auto;
            display: flex;
            height: 100%;
            align-items: center;

            h5 {
                position: relative;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #111;
            }

            input {
                position: relative;
                margin-left: 25px;
                width: 400px;
                height: 100%;
                border: 2px solid #E1E0EA;
                border-radius: 8px;
                outline: none;
                padding: 5px 25px;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                color: #444;

                &:focus,
                &:valid {
                    border: 2px solid #FC6736;
                }
            }
        }

        .add_btn {
            position: relative;
            margin-left: 40px;
            height: 100%;

            a {
                position: relative;
                width: 180px;
                height: 100%;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #3FE44F;
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #FFF;
                transition: all 0.5s;
                cursor: pointer;

                &:hover {
                    box-shadow: 5px 7px 15px rgba(0,0,0,0.3);
                    transition: all 0.5s;
                }

                i {
                    position: relative;
                    font-size: 17px;
                    margin-right: 8px;
                }
            }
        }
    }

    .table_sec {
        position: relative;
        width: 100%;
        height: calc(100% - 55px);
        padding-top: 40px;

        .table_inner {
            position: relative;
            width: 100%;
            height: 100%;

            table {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                thead {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    display: flex;
                    background: #FC6736;
                    border-radius: 10px 10px 0 0 ;
                    border: 1px solid #FC6736;

                    th {
                        position: relative;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-family: 'Lemonada', cursive;
                        font-size: 14px;
                        color: #FFF;
                        font-weight: 600;

                        &:nth-child(1) {
                            width: 7%;
                            padding: 0px 25px;
                        }

                        &:nth-child(2) {
                            width: 28%;
                            padding: 0px 25px;
                        }

                        &:nth-child(3) {
                            width: 27%;
                            padding: 0px 25px;
                        }

                        &:nth-child(4) {
                            width: 13%;
                            justify-content: center;
                        }

                        &:nth-child(5) {
                            width: 15%;
                            justify-content: center;
                            padding: 0px 25px;
                        }

                        &:nth-child(6) {
                            width: 10%;
                            justify-content: center;
                            padding: 0px 25px;
                        }
                    }
                }

                tbody {
                    position: relative;
                    width: 100%;
                    max-height: calc(100% - 55px);
                    border: 1px solid #E1E0EA;
                    border-radius: 0 0 10px 10px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    scrollbar-width: none;

                    tr {
                        position: relative;
                        width: 100%;
                        min-height: 55px;
                        display: flex;
                        border-bottom: 1px solid #E1E0EA;

                        &:last-of-type {
                            border-bottom: none;
                        }

                        &:nth-of-type(even) {
                            background: rgba(2, 192, 255, 0.15);
                        }

                        p {
                            position: relative;
                            width: 100%;
                            padding: 15px 25px;
                            display: flex;
                            justify-content: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;
                        }

                        td {
                            position: relative;
                            padding: 15px 25px;
                            display: flex;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;

                            &:nth-child(1) {
                                width: 7%;
                            }
                            &:nth-child(2) {
                                width: 28%;
                            }
                            &:nth-child(3) {
                                width: 27%;
                            }
                            &:nth-child(4) {
                                width: 13%;
                                justify-content: center;
                            }
                            &:nth-child(5) {
                                width: 15%;
                                justify-content: center;
                            }
                            &:nth-child(6) {
                                width: 10%;
                                justify-content: center;

                                span {
                                    position: relative;
                                    cursor: pointer;
                                    font-size: 16px;

                                    &.view {
                                        color: #02C0FF;
                                    }

                                    &.delete {
                                        margin-left: 10px;
                                        color: #FF33A1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const RequestMovieWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .table_sec {
        position: relative;
        width: 100%;
        height: calc(100% - 55px);
        padding-top: 60px;

        .table_inner {
            position: relative;
            width: 100%;
            height: 100%;

            table {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                thead {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    display: flex;
                    background: #FC6736;
                    border-radius: 10px 10px 0 0 ;
                    border: 1px solid #FC6736;

                    th {
                        position: relative;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-family: 'Lemonada', cursive;
                        font-size: 14px;
                        color: #FFF;
                        font-weight: 600;

                        &:nth-child(1) {
                            width: 8%;
                            padding: 0px 25px;
                        }

                        &:nth-child(2) {
                            width: 40%;
                            padding: 0px 25px;
                        }

                        &:nth-child(3) {
                            width: 40%;
                            padding: 0px 25px;
                        }

                        &:nth-child(4) {
                            width: 12%;
                            padding: 0px 25px;
                            justify-content: center;
                        }
                    }
                }

                tbody {
                    position: relative;
                    width: 100%;
                    max-height: calc(100% - 55px);
                    border: 1px solid #E1E0EA;
                    border-radius: 0 0 10px 10px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    scrollbar-width: none;

                    tr {
                        position: relative;
                        width: 100%;
                        min-height: 55px;
                        display: flex;
                        border-bottom: 1px solid #E1E0EA;

                        &:last-of-type {
                            border-bottom: none;
                        }

                        &:nth-of-type(even) {
                            background: rgba(2, 192, 255, 0.15);
                        }

                        p {
                            position: relative;
                            width: 100%;
                            padding: 15px 25px;
                            display: flex;
                            justify-content: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;
                        }

                        td {
                            position: relative;
                            padding: 15px 25px;
                            display: flex;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;

                            &:nth-child(1) {
                                width: 8%;
                            }

                            &:nth-child(2) {
                                width: 40%;
                            }

                            &:nth-child(3) {
                                width: 40%;
                            }

                            &:nth-child(4) {
                                width: 12%;
                                justify-content: center;

                                a {
                                    position: relative;
                                    cursor: pointer;
                                    font-size: 18px;
                                    text-decoration: none;

                                    &.add {
                                        color: #3FE44F;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const AddMovieWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 25px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        form {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            .form_top {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .left_part {
                    position: relative;
                    width: 250px;
                    height: 260px ;

                    input[type="file"] {
                        display: none;
                    }

                    .preview_box {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #E1E0EA;
                        border-radius: 6px;
                        overflow: hidden;
                        cursor: pointer;

                        .text_box {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            padding: 30px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            color: #cfcfcf;

                            i {
                                font-size: 45px;
                            }

                            p {
                                font-size: 15px;
                                line-height: 1.3;
                                margin-top: 15px;
                            }
                        }

                        .img_box {
                            position: relative;
                            width: 100%;
                            height: 100%;

                            img {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }

                .right_part {
                    position: relative;
                    width: calc(100% - 250px);
                    padding-left: 50px;

                    .right_inner {
                        position: relative;
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .input_box {
                            position: relative;
                            margin-bottom: 30px;

                            &.nameBox {
                                width: 65%;
                            }

                            input {
                                position: relative;
                                width: 100%;
                                height: 55px;
                                border: 2px solid #E1E0EA;
                                border-radius: 6px;
                                padding: 5px 25px;
                                outline: none;

                                &:focus,
                                &:valid {
                                    border: 2px solid #FC6736;
                                    transition: 0.5s;
                                }
                            }

                            span {
                                position: absolute;
                                top: 50%;
                                left: 25px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                line-height: 1;
                                color: #555;
                                pointer-events: none;
                                transform: translateY(-50%);
                                transition: 0.5s;
                            }

                            input:focus ~ span,
                            input:valid ~ span {
                                top: 0%;
                                left: 15px;
                                font-size: 13px;
                                padding: 0px 8px;
                                background: #FFF;
                                color: #02C0FF;
                                font-weight: 500;
                                transition: 0.5s;
                            }
                        }

                        .select_box {
                            position: relative;
                            margin-bottom: 30px;

                            &.languageBox {
                                width: 32%;
                            }

                            &.activityBox {
                                width: 22%;
                            }

                            &.typeBox {
                                width: 75%;
                            }

                            input {
                                display: none;
                            }

                            .dropdown_btn {
                                position: relative;
                                width: 100%;
                                height: 55px;
                                border: 2px solid #E1E0EA;
                                border-radius: 6px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 5px 25px;
                                font-family: 'Poppins', sans-serif;
                                cursor: pointer;

                                span {
                                    position: absolute;
                                    top: 50%;
                                    left: 25px;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    line-height: 1;
                                    color: #555;
                                    transform: translateY(-50%);
                                    transition: 0.5s;
                                }

                                p {
                                    position: relative;
                                    max-width: calc(100% - 15px);
                                    padding-right: 15px;
                                    font-size: 16px;
                                    line-height: 1.5;
                                    color: #555;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                i {
                                    font-size: 15px;
                                    transition: 0.7s;

                                    &.active {
                                        transform: rotate(-180deg);
                                        transition: 0.5s;
                                    }
                                }
                            }

                            input:focus ~ .dropdown_btn,
                            input:valid ~ .dropdown_btn {
                                border: 2px solid #FC6736;
                                transition: 0.5s;
                            }

                            input:focus ~ .dropdown_btn span,
                            input:valid ~ .dropdown_btn span {
                                top: 0%;
                                left: 15px;
                                font-size: 13px;
                                padding: 0px 8px;
                                background: #FFF;
                                color: #02C0FF;
                                font-weight: 500;
                                transition: 0.5s;
                            }

                            .dropdown {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 100%;
                                z-index: 15;
                                background: #FFF;
                                border-radius: 5px;
                                box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                                max-height: 0;
                                overflow: hidden;
                                transition: all 0.8s;

                                &.active {
                                    max-height: 500px;
                                    transition: all 0.8s;
                                }

                                ul {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    padding: 20px;

                                    li {
                                        position: relative;
                                        list-style: none;
                                        margin: 10px;

                                        &.fullWidth {
                                            width: 100%;
                                        }

                                        input[type="checkbox"] {
                                            display: none;
                                        }

                                        label {
                                            position: relative;
                                            padding: 10px 20px;
                                            background: #E1E0EA;
                                            cursor: pointer;
                                            border-radius: 5px;
                                            font-size: 14px;
                                            line-height: 1;
                                            display: flex;
                                            align-items: center;
                                            transition: all 0.5s;

                                            .check_box {
                                                position: relative;
                                                width: 20px;
                                                height: 20px;
                                                display: none;
                                                align-items: center;
                                                justify-content: center;
                                                background: #3FE44F;
                                                border-radius: 50%;
                                                font-size: 10px;
                                                color: #FFF;
                                                margin-right: 7px;
                                                transition: all 0.5s;
                                            }
                                        }

                                        input[type="checkbox"]:checked ~ label {
                                            background: #02C0FF;
                                            color: #FFF;
                                            transition: all 0.5s;
                                        }

                                        input[type="checkbox"]:checked ~ label .check_box {
                                            display: flex;
                                            transition: all 0.5s;
                                        }
                                    }
                                }
                            }
                        }

                        .date_box {
                            position: relative;
                            width: 47.5%;
                            display: flex;
                            align-items: center;
                            margin-bottom: 30px;

                            span {
                                position: relative;
                                width: 110px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                color: #111;
                            }

                            .date_input {
                                position: relative;
                                width: calc(100% - 110px);
                                padding-left: 25px;

                                input {
                                    position: relative;
                                    width: 100%;
                                    height: 55px;
                                    border: 2px solid #E1E0EA;
                                    border-radius: 6px;
                                    padding: 5px 25px;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16PX;
                                    color: #555;
                                    outline: none;
                                    
                                    &:focus,
                                    &:valid {
                                        border: 2px solid #FC6736;
                                    }
                                }
                            }
                        }

                        .time_box {
                            position: relative;        
                            width: 47.5%;
                            display: flex;
                            align-items: center;
                            margin-bottom: 30px;

                            span {
                                position: relative;
                                width: 95px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                color: #111;
                            }

                            .time_inputs {
                                position: relative;
                                width: calc(100% - 95px);
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-left: 25px;

                                .hour_input {
                                    position: relative;
                                    display: flex;
                                    align-items: center;

                                    input {
                                        position: relative;
                                        width: auto;
                                        max-width: 155px;
                                        height: 55px;
                                        border: 2px solid #E1E0EA;
                                        border-radius: 6px;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 16PX;
                                        color: #555;
                                        padding: 5px 25px;
                                        text-align: center;
                                        outline: none;

                                        &:focus,
                                        &:valid {
                                            border: 2px solid #FC6736;
                                        }
                                    }

                                    p {
                                        position: relative;
                                        font-family: 'Poppins', sans-serif;
                                        margin-left: 8px;
                                    }
                                }

                                .minute_input {
                                    position: relative;
                                    display: flex;
                                    align-items: center;

                                    input {
                                        position: relative;
                                        width: auto;
                                        max-width: 155px;
                                        height: 55px;
                                        border: 2px solid #E1E0EA;
                                        border-radius: 6px;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 16PX;
                                        color: #555;
                                        padding: 5px 25px;
                                        text-align: center;
                                        outline: none;

                                        &:focus,
                                        &:valid {
                                            border: 2px solid #FC6736;
                                        }
                                    }

                                    p {
                                        position: relative;
                                        font-family: 'Poppins', sans-serif;
                                        margin-left: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .form_bottom {
                position: relative;
                width: 100%;
                margin-top: 30px;
                display: flex;
                flex-direction: column;

                .trailer_input_box {
                    position: relative;
                    width: 100%;
                    margin-bottom: 30px;

                    .input_box {
                        position: relative;
                        width: 100%;

                        input {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            padding: 5px 25px;
                            border-radius: 6px;
                            border: 2px solid #E1E0EA;
                            outline: none;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            color: #111;
                            transition: 0.5s;

                            &:focus,
                            &:valid {
                                border: 2px solid #FC6736;
                                transition: 0.5s;
                            } 
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            pointer-events: none;
                            transition: all 0.5s;
                        }

                        input:focus ~ span,
                        input:valid ~ span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: all 0.5s;
                        }
                    }
                }

                .member_input_box {
                    position: relative;
                    width: 100%;
                    margin-bottom: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .input_box {
                        position: relative;
                        width: calc(100% - 250px);
                        padding-right: 30px;
                        display: flex;
                        flex-direction: column;

                        input {
                            position: relative;
                            width: 100%;
                            height: 55px;
                            padding: 5px 25px;
                            border-radius: 6px;
                            border: 2px solid #E1E0EA;
                            outline: none;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            color: #111;
                            transition: 0.5s;

                            &:focus,
                            &:valid {
                                border: 2px solid #FC6736;
                                transition: 0.5s;
                            } 
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            opacity: 0;
                            pointer-events: none;
                            transition: all 0.5s;
                        }

                        input:focus ~ span,
                        input:valid ~ span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            opacity: 1;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: all 0.5s;
                        }

                        b {
                            position: relative;
                            margin-top: 6px;
                            font-size: 14px;
                            line-height: 1;
                            padding-left: 20px;
                            font-weight: 500;
                            color: #f00;
                        }
                    }

                    p {
                        position: relative;
                        width: 250px;
                        height: 55px;
                        background: #02C0FF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFF;
                        border-radius: 6px;
                        cursor: pointer;

                        i {
                            position: relative;
                            font-size: 18px;
                            margin-right: 10px;
                        }
                    }
                }

                .about_input_box {
                    position: relative;
                    width: 100%;
                    margin-bottom: 30px;

                    textarea {
                        position: relative;
                        width: 100%;
                        height: 120px;
                        padding: 15px 25px;
                        border-radius: 6px;
                        border: 2px solid #E1E0EA;
                        outline: none;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        color: #111;
                        resize: none;
                        transition: 0.5s;

                        &:focus,
                        &:valid {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }
                    }

                    span {
                        position: absolute;
                        top: 25px;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        transform: translateY(-50%);
                        transition: all 0.5s;
                    }

                    textarea:focus ~ span,
                    textarea:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: all 0.5s;
                    }
                }

                .form_btn {
                    position: relative;
                    margin-top: 10px;

                    button {
                        position: relative;
                        width: 220px;
                        height: 55px;
                        background: #3FE44F;
                        border: none;
                        border-radius: 6px;
                        outline: none;
                        font-family: 'Poppins', sans-serif;
                        font-size: 17px;
                        font-weight: 600;
                        letter-spacing: 0.66px;
                        text-transform: uppercase;
                        color: #FFF;
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;

export const ReviewWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .filter_search_sec {
        position: relative;
        width: 100%;
        height: 55px;
        display: flex;
        align-items: center;

        .filter_box {
            position: relative;
            display: flex;
            height: 100%;
            align-items: center;

            h5 {
                position: relative;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #111;
            }

            .select_box {
                position: relative;
                height: 100%;
                margin-left: 25px;

                input {
                    display: none;
                }

                .dropdown_btn {
                    position: relative;
                    width: 225px;
                    height: 100%;
                    border: 2px solid #E1E0EA;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 25px;
                    font-family: 'Poppins', sans-serif;
                    cursor: pointer;

                    p {
                        position: relative;
                        font-size: 16px;
                        color: #444;
                    }

                    i{
                        position: relative;
                        color: #444;
                        transition: all 0.5s;

                        &.active {
                            transform: rotate(-180deg);
                            transition: all 0.5s;
                        }
                    }
                }

                input:focus ~ .dropdown_btn,
                input:valid ~ .dropdown_btn {
                    border: 2px solid #FC6736;
                }

                .dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background: #FFF;
                    z-index: 50;
                    border-radius: 5px;
                    box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                    max-height: 0;
                    overflow: hidden;
                    transition: all 0.6s;

                    &.active {
                        max-height: 300px;
                        transition: all 0.6s;
                    }

                    ul {
                        position: relative;
                        width: 100%;
                        padding: 10px 10px;

                        li {
                            position: relative;
                            list-style: none;
                            margin: 5px 0;
                            padding: 10px 15px;
                            color: #555;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            line-height: 1;
                            border-radius: 4px;
                            transition: all 0.5s;
                            cursor: pointer;
                            
                            &:hover {
                                background: #FC6736;
                                color: #FFF;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }

        .search_box {
            position: relative;
            margin-left: auto;
            display: flex;
            height: 100%;
            align-items: center;

            h5 {
                position: relative;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #111;
            }

            input {
                position: relative;
                margin-left: 25px;
                width: 400px;
                height: 100%;
                border: 2px solid #E1E0EA;
                border-radius: 8px;
                outline: none;
                padding: 5px 25px;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                color: #444;

                &:focus,
                &:valid {
                    border: 2px solid #FC6736;
                }
            }
        }
    }

    .table_sec {
        position: relative;
        width: 100%;
        height: calc(100% - 55px);
        padding-top: 40px;

        .table_inner {
            position: relative;
            width: 100%;
            height: 100%;

            table {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                thead {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    display: flex;
                    background: #FC6736;
                    border-radius: 10px 10px 0 0 ;
                    border: 1px solid #FC6736;

                    th {
                        position: relative;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-family: 'Lemonada', cursive;
                        font-size: 14px;
                        color: #FFF;
                        font-weight: 600;

                        &:nth-child(1) {
                            width: 7%;
                            padding: 0px 25px;
                        }

                        &:nth-child(2) {
                            width: 18%;
                            padding: 0px 25px;
                        }

                        &:nth-child(3) {
                            width: 22%;
                            padding: 0px 25px;
                        }

                        &:nth-child(4) {
                            width: 13%;
                            padding: 0px 25px;
                            justify-content: center;
                        }

                        &:nth-child(5) {
                            width: 30%;
                            padding: 0px 25px;
                        }

                        &:nth-child(6) {
                            width: 10%;
                            padding: 0px 25px;
                            justify-content: center;
                        }
                    }
                }

                tbody {
                    position: relative;
                    width: 100%;
                    max-height: calc(100% - 55px);
                    border: 1px solid #E1E0EA;
                    border-radius: 0 0 10px 10px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    scrollbar-width: none;

                    tr {
                        position: relative;
                        width: 100%;
                        min-height: 55px;
                        display: flex;
                        border-bottom: 1px solid #E1E0EA;

                        &:last-of-type {
                            border-bottom: none;
                        }

                        &:nth-of-type(even) {
                            background: rgba(2, 192, 255, 0.15);
                        }

                        td {
                            position: relative;
                            padding: 15px 25px;
                            display: flex;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;

                            &:nth-child(1) {
                                width: 7%;
                            }

                            &:nth-child(2) {
                                width: 18%;
                            }

                            &:nth-child(3) {
                                width: 22%;
                            }

                            &:nth-child(4) {
                                width: 13%;
                                justify-content: center;
                            }

                            &:nth-child(5) {
                                width: 30%;
                                
                                span {
                                    position: relative;
                                    width: 100%;
                                    max-height: 65px;
                                    display: -webkit-box;
                                    overflow: hidden;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                    text-overflow: ellipsis;
                                }
                            }

                            &:nth-child(6) {
                                width: 10%;
                                justify-content: center;

                                span {
                                    position: relative;
                                    cursor: pointer;
                                    font-size: 16px;

                                    &.view {
                                        color: #02C0FF;
                                    }

                                    &.delete {
                                        margin-left: 10px;
                                        color: #FF33A1;
                                    }
                                }
                            }
                        }

                        p {
                            position: relative;
                            width: 100%;
                            padding: 15px 25px;
                            display: flex;
                            justify-content: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
`;