import React, { useEffect, useState } from 'react';
import { DashboardWrapper } from "../Styles/Dashboard-Style";
import UserData from '../Charts/UserData-Chart';
import MovieData from '../Charts/MovieData-Chart';
import TotalUserBookingData from '../Charts/TotalUserBookingData-Chart';
import MovieBookingData from '../Charts/MovieBookingData-Chart';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Dashboard_Data_URL } from '../API/Api';



function Dashboard() {

    const [userCount, setUserCount] = useState('');
    const [theaterCount, setTheaterCount] = useState('');
    const [moviesCount, setMoviesCount] = useState('');
    const [todayShowCount, setTodayShowCount] = useState('');
    const [moviesData, setMoviesData] = useState({
        thisWeek: '',
        upcoming: '',
        today: ''
    });
    const [totalUserBookingData, setTotalUserBookingData] = useState({
        total_users: '',
        total_bookings: ''
    });


    useEffect(() => {
        axios.get(Dashboard_Data_URL)
        .then(res => {
            console.log(res.data.dashboard_data);
            setUserCount(res.data.dashboard_data.user_count);
            setTheaterCount(res.data.dashboard_data.theater_count);
            setMoviesCount(res.data.dashboard_data.movie_count);
            setTodayShowCount(res.data.dashboard_data.today_show_count);
            setMoviesData({
                thisWeek: res.data.dashboard_data.week_show_count,
                upcoming: res.data.dashboard_data.upcoming_movie_count,
                today: res.data.dashboard_data.today_show_count
            });
            setTotalUserBookingData({
                total_users: res.data.dashboard_data.user_count,
                total_bookings: res.data.dashboard_data.total_bookings
            })
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    // Check if all values 0
    const isMoviesDataEmpty = moviesData.thisWeek === 0 && moviesData.upcoming === 0 && moviesData.today === 0;
    const isTotalUserBookingEmpty = totalUserBookingData.total_users === 0 && totalUserBookingData.total_bookings === 0;

    return(
        <>
            <DashboardWrapper>
                <div className="dashboard_inner">
                    <div className="dashboard_box_sec">
                        <Link to="/panel/users" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Total No. Users</h4>
                                    <p>{userCount}</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-users"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/theaters-listed" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Total No. Theaters</h4>
                                    <p>{theaterCount}</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-clapperboard"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/movies" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Uploaded Movies</h4>
                                    <p>{moviesCount}</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-film"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/today-showing" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Today Showing</h4>
                                    <p>{todayShowCount}</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-video"></i>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="superadmin_graph_sec">
                        <div className="user_graph">
                            <h4>Users & Online Bookings</h4>
                            <div className="graph_box">
                                <UserData />
                            </div>
                        </div>
                        <div className="movie_graph">
                            <h4>Movies' Data</h4>
                            <div className="graph_box">
                                {
                                    isMoviesDataEmpty ? 
                                    <h5>No Data Available</h5>
                                    :
                                    <MovieData moviesData={moviesData} />
                                }
                            </div>
                        </div>
                        <div className="Booking_graph">
                            <h4>Users & Bookings ( Total )</h4>
                            <div className="graph_box">
                                {
                                    isTotalUserBookingEmpty ? 
                                    <h5>No Data Available</h5>
                                    :
                                    <TotalUserBookingData totalUserBookingData={totalUserBookingData} />
                                }
                            </div>
                        </div>
                        <div className="movie_booking_graph">
                            <h4>Online & Offline Bookings</h4>
                            <div className="graph_box">
                                <MovieBookingData />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardWrapper>
        </>
    );

}


export default Dashboard;