import { useEffect, useState } from "react";
import { ModalTheaterDetailsBox } from "../Styles/Modal-Style";
import axios from "axios";
import { Fetch_Theater_Details_URL } from "../API/Api";
import ModalConfirmDelete from "./ConfirmDeleteModal";



function ModalTheaterDetails({theaterDetailsModalShow, setTheaterDetailsModalShow, theaterName, setTheaterName}) {
    
    const [screenNo, setScreenNo] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [onlineBookings, setOnlineBookings] = useState('');
    const [offlineBookings, setOfflineBookings] = useState('');
    const [adminCommission, setAdminCommission] = useState({});
    const [commission, setCommission] = useState('');
    const [count, setCount] = useState(false);
    const [shows, setShows] = useState([]);

    useEffect(() => {

        axios.get(`${Fetch_Theater_Details_URL}?name=${theaterName}`)
        .then(res => {
            console.log(res.data.admin_commissions[0]);
            setScreenNo(res.data.screen_no);
            setGstNo(res.data.gst_no);
            setOnlineBookings(res.data.online_bookings);
            setOfflineBookings(res.data.offline_bookings);
            setAdminCommission(res.data.admin_commissions[0]);
            setCommission(res.data.commission);
            const moviesWithFormattedDate = res.data.shows.map(show => ({
                ...show,
                formatted_date: show.date.split(',')[0] // Use the backend formatted_date or extract it here
            }));

            if(res.data.count > 0){
                setCount(true);
                setShows(moviesWithFormattedDate);
            } else {
                setCount(false);
                setShows([]);
            }
        })
        .catch(err => {
            // console.log(err);
            setCount(false);
        })

    }, [theaterName]);

    const closeModal = () => {
        setTheaterName('');
        setTheaterDetailsModalShow(false);
    };

    return(
        <>
            <ModalTheaterDetailsBox className={theaterDetailsModalShow ? 'active' : ''}>
                <div className={`details_inner ${theaterDetailsModalShow ? 'active' : ''}`}>
                    <div className="top_part">
                        <div className="top_inner">
                            <h3>{theaterName}</h3>
                            <a onClick={closeModal}><i class="fa-solid fa-arrow-left-long"></i>Back</a>
                        </div>
                    </div>
                    <div className="body_part">
                        <div className="info_box_sec">
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Total No. Screens</h4>
                                        <p>{screenNo}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-clapperboard"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>GST No.</h4>
                                        <p>{gstNo}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-file-shield"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Online Bookings</h4>
                                        <p>{onlineBookings}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-house-signal"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Offline Bookings</h4>
                                        <p>{offlineBookings}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-ticket"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="info_box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Commission (Rs.)</h4>
                                        <p>{commission}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-hand-holding-dollar"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            adminCommission && 
                            <div className="admin_commission_sec">
                                <h3>Admin Convenience Fees Structure</h3>
                                <div className="table_sec">
                                    <table>
                                        <thead>
                                            <th>Price Range (Rs.)</th>
                                            {
                                                Object.keys(adminCommission).map((range, index) => (
                                                    <th key={index}>{range}</th>
                                                ))
                                            }
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Charge (Rs.)</td>
                                                {
                                                    Object.values(adminCommission).map((value, index) => (
                                                        <td key={index}>{value !== null ? value : 'N/A'}</td>
                                                    ))
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        <div className="settlement_sec">
                            <div className="settlement_price_sec">
                                <h3>Ticket Price Settlement</h3>
                                <div className="table_sec">
                                    <table>
                                        <thead>
                                            <th>Sl. No.</th>
                                            <th>Date</th>
                                            <th>Amount (Rs.)</th>
                                            <th>Payment</th>
                                            <th>Invoice</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1.</td>
                                                <td>24 Aug, 2024</td>
                                                <td>1000.00</td>
                                                <td>
                                                    <div className="check_box">
                                                        <input type="checkbox" name="payment-check" id="1" />
                                                        <label htmlFor="1">
                                                            <i className="fa-solid fa-check"></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td><a><i className="fa-solid fa-download"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="settlement_price_sec">
                                <h3>Theater Commission Settlement</h3>
                                <div className="table_sec">
                                    <table>
                                        <thead>
                                            <th>Sl. No.</th>
                                            <th>Date</th>
                                            <th>Amount (Rs.)</th>
                                            <th>Payment</th>
                                            <th>Invoice</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1.</td>
                                                <td>24 Aug, 2024</td>
                                                <td>1000.00</td>
                                                <td>
                                                    <div className="check_box">
                                                        <input type="checkbox" name="payment-check" id="1" />
                                                        <label htmlFor="1">
                                                            <i className="fa-solid fa-check"></i>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td><a><i className="fa-solid fa-download"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="movie_list_sec">
                            <h3>Movie List</h3>
                            <div className="table_sec">
                                <div className="sec_inner">
                                    <table>
                                        <thead>
                                            <th>Sl. No.</th>
                                            <th>Movie Name</th>
                                            <th>Language</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Ticked Booked</th>
                                        </thead>
                                        <tbody>
                                            {
                                                count ?
                                                (shows && shows.map((show, index) => 
                                                    <tr key={index}>
                                                        <td>{index + 1}.</td>
                                                        <td>{show.movie_name}</td>
                                                        <td>{show.language}</td>
                                                        <td>{show.formatted_date}</td>
                                                        <td>{show.time}</td>
                                                        <td>{show.total_booking}</td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <p>No Movies Found</p>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalTheaterDetailsBox>
        </>
    );
}


export default ModalTheaterDetails;