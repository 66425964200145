import { useState } from "react";
import ReactApexChart from 'react-apexcharts';





function TotalUserBookingData({totalUserBookingData}) {

    const [series, setSeries] = useState([
        { name: 'Total Users', data: totalUserBookingData.total_users },
        { name: 'Total Bookings', data: totalUserBookingData.total_bookings },
    ]);

    const [options, setOptions] = useState({
        chart: {
            width: 450,
            type: 'donut',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            position: 'right',
            offsetY: -20,
            offsetX: 5,
            height: 230,
        },
        colors: ['#1C41FF', '#FF5733'],
        labels: series.map(item => item.name),
    });

    return(
        <>
            <div id="chart">
                <ReactApexChart  options={options} series={series.map(item => item.data)} type="donut" width={450} />
            </div>
            <div id="html-dist"></div>
        </>
    );
}


export default TotalUserBookingData;